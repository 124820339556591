@font-face {
  font-family: xkcd;
  src: url("/css/xkcd.otf") format("opentype");
}

 @font-face {
    font-family: Roboto;
    src: url(sansation_light.woff);
    src: url("/css/roboto/Roboto-Regular.ttf") format("ttf"),
         url("/css/roboto/Roboto-Italic.ttf") format("ttf"),
         url("/css/roboto/Roboto-Bold.ttf") format("ttf"),
         url("/css/roboto/Roboto-BoldItalic.ttf") format("ttf");

}

.read-on {
    background-color: rgba(128, 128, 128, 0.1);
    padding: 5px;
    border-style: solid;
    border-width: 1px;
    border-color: rgba(128, 128, 128, 0.3);
    font-family: $nav-font-family;
}

.search-field {
    font-size: 16pt;
    width: 10em;
    height: 1.0em;
    @media (prefers-color-scheme: dark) {
        background-color: #444;
        color: var(--text-color);
        border-color: #666;
        border-width: 1px;
        border-style: solid;
    }
}
.btn-expand {
    font-size: 16px !important;
}

.right {
    float: right;
}
.left {
    float: left;
}

/*Github's table CSS*/
table {
  padding: 0px;
  // margin: 0px;
  max-width: 100%;
  // display: block;
  border-collapse: collapse;
  //border-padding: 0px;
  border-width: 2px;
  overflow-x: scroll;
  margin-left: auto;
  margin-right: auto;
}
table tr {
  border-top: 1px solid #cccccc;
  // background-color: white;
  // margin: 0px;
  padding: 0;
}
table tr:nth-child(2n) {
  background-color: #f8f8f8;
  @media (prefers-color-scheme: dark) {
      background-color: #070707;
  }
}
table tr th {
  font-weight: bold;
  border: 1px solid #cccccc;
  @media (prefers-color-scheme: dark) {
      border: 1px solid #666;
  }
  text-align: left;
  // margin: 0;
  padding: 6px 13px;
}
table tr td {
  border: 1px solid #cccccc;
  @media (prefers-color-scheme: dark) {
      border: 1px solid #666;
  }
  text-align: left;
  // margin: 0;
  padding: 6px 13px;
}
table tr th :first-child, table tr td :first-child {
  margin-top: 0;
}
table tr th :last-child, table tr td :last-child {
  margin-bottom: 0;
}

/**
 * Site header
 */
.site-header {
    border-top: 1px solid $grey-color-light;
    border-bottom: 1px solid $grey-color-light;
    min-height: 56px;
    max-height: 56px;
    background-color: var(--menu-color);
    a {
        @media (prefers-color-scheme: dark) {
            color: #888; //var(--font-color) !important;//#aaa !important;
        }
        &:hover {
            color: var(--hover-link-color) !important;
        }
    }
    // Positioning context for the mobile navigation icon
    position: relative;
    @media (prefers-color-scheme: dark) {
        border-top: 1px solid #222;
        border-bottom: 1px solid #555;
    }
}


.site-title {
    font-size: $header-font-size;
    line-height: 60px;
    letter-spacing: 0.03em;
    font-family: $nav-font-family !important;

    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-bottom: 0px !important;
    padding-bottom: 0px !important;
}

.site-nav {
    font-size: $nav-font-size;
    line-height: 0px;
    font-family: $nav-font-family;
    letter-spacing: 0.03em;

    float: right;
    padding-left: 5px !important;
    padding-right: 5px !important;
    margin-bottom: 0px !important;
    padding-bottom: 10px !important;

    .menu-icon {
        display: none;
    }

    .page-link {
        line-height: 66px;
        float: right;
        padding-left: 5px !important;
        padding-right: 5px !important;
        margin-bottom: 0px !important;
        padding-bottom: 0px !important;
        margin-left: 5px;
        margin-right: 5px;
    }

    @include media-query($on-palm) {
        line-height: 26px;
        position: absolute;
        top: 9px;
        right: $spacing-unit / 2;
        background-color: $background-color;
        border: 1px solid $grey-color-light;
        border-radius: 5px;
        text-align: right;
        @media (prefers-color-scheme: dark) {
            background-color: #222;
            border-color: #777;
        }
        // Gaps between nav items, but not on the last one
        // &:not(:last-child) {
            // margin-right: 20px;
        // }

        .menu-icon {
            display: block;
            float: right;
            width: 36px;
            height: 16px;
            line-height: 0;
            padding-top: 8px;
            padding-bottom: 0px;// !important;
            text-align: center;

            > svg {
                width: 18px;
                height: 15px;

                path {
                    fill: $grey-color-dark;
                }
            }
        }

        .trigger {
            clear: both;
            display: none;
        }

        &:hover .trigger {
            display: block;
            padding-bottom: 5px;
        }

        .page-link {
            display: inline-block;
            // padding: 5px 10px;

            &:not(:last-child) {
                margin-right: 0;
            }
            margin-left: 5px;
        }
    }
}



/**
 * Site footer
 */
.site-footer {
    border-top: 1px solid $grey-color-light;
    padding: $spacing-unit 0;
    background-color: var(--footer-color);
    font-family: $nav-font-family;
    @media (prefers-color-scheme: dark) {
        border-top: 1px solid $grey-color-dark;
    }
}

.footer-heading {
    font-size: 18px;
    margin-bottom: $spacing-unit / 2;
}

.contact-list,
.social-media-list {
    list-style: none;
    margin-left: 0;
}

.footer-col-wrapper {
    font-size: 15px;
    color: $grey-color;
    margin-left: -$spacing-unit / 2;
    @extend %clearfix;
    font-family: $nav-font-family,sans-serif;
}

.footer-col {
    float: left;
    margin-bottom: $spacing-unit / 2;
    padding-left: $spacing-unit / 2;
}

.footer-col-1 {
    width: -webkit-calc(35% - (#{$spacing-unit} / 2));
    width:         calc(35% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
    width: -webkit-calc(20% - (#{$spacing-unit} / 2));
    width:         calc(20% - (#{$spacing-unit} / 2));
}

.footer-col-3 {
    width: -webkit-calc(45% - (#{$spacing-unit} / 2));
    width:         calc(45% - (#{$spacing-unit} / 2));
}

@include media-query($on-laptop) {
    .footer-col-1,
    .footer-col-2 {
        width: -webkit-calc(50% - (#{$spacing-unit} / 2));
        width:         calc(50% - (#{$spacing-unit} / 2));
    }

    .footer-col-3 {
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}

@include media-query($on-palm) {
    .footer-col {
        float: none;
        width: -webkit-calc(100% - (#{$spacing-unit} / 2));
        width:         calc(100% - (#{$spacing-unit} / 2));
    }
}



/**
 * Page content
 */
.page-content {
    padding: $spacing-unit 0;
}

.page-heading {
    font-size: 20px;
}

.post-list {
    margin-left: 0;
    list-style: none;

    > li {
        margin-bottom: $spacing-unit;
    }
}

.post-meta {
    font-size: 18px;//$small-font-size;
    color: $grey-color;
    font-style: normal;
    font-family: $nav-font-family;
}

.post-link {
  //display: block;
  font-family: $nav-font-family;
  font-size: 24px;
  line-height: 1.1;
}

.post-category{
    background: rgba(127, 127, 127, 0.1);
    -webkit-border-radius: 5px;
    -moz-border-radius:    5px;
    -o-border-radius:      5px;
    -ms-border-radius:     5px;
    -khtml-border-radius:  5px;
    border-radius:         5px;
    padding: .1em .4em;
    margin: 0;
    @media (prefers-color-scheme: dark) {
        background: rgba(127, 127, 127, 0.1);
    }
}
.post-info{
    padding-top: 0px;
    font-size: 16px;
    line-height: 0;
    font-family: $nav-font-family;
}

/**
 * Posts
 */
.post-header {
    margin-bottom: $spacing-unit;
}

.post-title {
    //font-size: 48px !important;
    //font-weight: 400 !important;
    line-height: 1.107646;
    //letter-spacing: 0.004rem;
    // text-align: center;
    overflow-wrap: normal;
    font-weight: 400 !important;
    padding-bottom: 0px;

    //@include media-query($on-laptop) {
    //    font-size: 36px !important;
    //}
}

.post-content {
    margin-bottom: $spacing-unit;

    h2 {
        font-size: 32px;

        @include media-query($on-laptop) {
            font-size: 28px;
        }
    }

    h3 {
        font-size: 26px;

        @include media-query($on-laptop) {
            font-size: 22px;
        }
    }

    h4 {
        font-size: 20px;

        @include media-query($on-laptop) {
            font-size: 18px;
        }
    }
}
/*
 * Header link placement and hover behavior
 */
.header-link {
  position: absolute;
  // position: static;
  // left: 1em;
  // padding-right: 0.5em;
  margin-left: -30px;
  margin-top: 0px;
  opacity: 0.0;

  -webkit-transition: opacity 0.2s ease-in-out 0.1s;
  -moz-transition: opacity 0.2s ease-in-out 0.1s;
  -ms-transition: opacity 0.2s ease-in-out 0.1s;
}
.header-link-img {
  //filter: invert(0.5) sepia(1) saturate(5) hue-rotate(175deg);  // black to blue, from [1]
  filter: invert(0.4) sepia(0.5) saturate(100) hue-rotate(200deg);  // black to blue, from [1]
  width: 25px;
  // [1] https://stackoverflow.com/questions/22252472/how-to-change-the-color-of-an-svg-element/49049008#49049008
  padding: 0px;
  padding-bottom: 1px;
  margin: 0px;
  border-bottom-style: none;
  border-bottom-width: 2px;
}

h2:hover .header-link,
h3:hover .header-link,
h4:hover .header-link,
h5:hover .header-link,
h6:hover .header-link {
  opacity: 1.0;
  // margin-left: -5px;
}
.header-link-img:hover {
    //border-bottom-color: blue;
    border-bottom-color: red;  // somehow turns blue; maybe with color rotation in .header-link?
    border-bottom-style: solid;
}
h2:hover .header-link {
    //padding-top: 5px;
}
.octicon {
  display: inline-block;
  vertical-align: text-top;
  fill: currentColor;
}

// From https://github.com/KaTeX/KaTeX/issues/327#issuecomment-291382715
.katex-display > .katex {
  display: inline-block;
  white-space: nowrap;
  max-width: 100%;
  overflow-x: scroll;
  text-align: initial;
}
//.katex {
//  font: normal 1.21em KaTeX_Main, Times New Roman, serif;
//  line-height: 1.2;
//  white-space: normal;
//  text-indent: 0;
//}

#footnote_box {
  background-color: var(--menu-color);
  font-size: $extra-small-font-size;
  //line-height: 18px;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 1em;
  padding-right: 1em;
  margin-top: 2em;
  border: 1px solid #777;
  //-moz-box-shadow: 0px 0px 5px #777;
  //-webkit-box-shadow: 0px 0px 5px #777;
  //box-shadow: 0px 0px 1px #777;
}
