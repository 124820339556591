// One that game with Jekyll I think. I'm not sure.
// I think this is solarized, with slight modifications to background color
.highlight .hll { background-color: #ffffcc }
.highlight .c { color: #93a1a1; font-style: italic } /* Comment */
.highlight .err { color: #dc322f } /* Error */
.highlight .g { color: #657b83 } /* Generic */
.highlight .k { color: #859900 } /* Keyword */
.highlight .l { color: #657b83 } /* Literal */
.highlight .n { color: #586e75 } /* Name */
.highlight .o { color: #657b83 } /* Operator */
.highlight .x { color: #657b83 } /* Other */
.highlight .p { color: #657b83 } /* Punctuation */
.highlight .cm { color: #93a1a1; font-style: italic } /* Comment.Multiline */
.highlight .cp { color: #93a1a1; font-style: italic } /* Comment.Preproc */
.highlight .c1 { color: #93a1a1; font-style: italic } /* Comment.Single */
.highlight .cs { color: #93a1a1; font-style: italic } /* Comment.Special */
.highlight .gd { color: #657b83 } /* Generic.Deleted */
.highlight .ge { color: #657b83 } /* Generic.Emph */
.highlight .gr { color: #657b83 } /* Generic.Error */
.highlight .gh { color: #657b83 } /* Generic.Heading */
.highlight .gi { color: #657b83 } /* Generic.Inserted */
.highlight .go { color: #657b83 } /* Generic.Output */
.highlight .gp { color: #657b83 } /* Generic.Prompt */
.highlight .gs { color: #657b83 } /* Generic.Strong */
.highlight .gu { color: #657b83 } /* Generic.Subheading */
.highlight .gt { color: #657b83 } /* Generic.Traceback */
.highlight .kc { color: #859900 } /* Keyword.Constant */
.highlight .kd { color: #859900 } /* Keyword.Declaration */
.highlight .kn { color: #cb4b16 } /* Keyword.Namespace */
.highlight .kp { color: #cb4b16 } /* Keyword.Pseudo */
.highlight .kr { color: #859900 } /* Keyword.Reserved */
.highlight .kt { color: #859900 } /* Keyword.Type */
.highlight .ld { color: #657b83 } /* Literal.Date */
.highlight .m { color: #2aa198 } /* Literal.Number */
.highlight .s { color: #2aa198 } /* Literal.String */
.highlight .na { color: #657b83 } /* Name.Attribute */
.highlight .nb { color: #268bd2 } /* Name.Builtin */
.highlight .nc { color: #268bd2 } /* Name.Class */
.highlight .no { color: #b58900 } /* Name.Constant */
.highlight .nd { color: #cb4b16 } /* Name.Decorator */
.highlight .ni { color: #cb4b16 } /* Name.Entity */
.highlight .ne { color: #cb4b16 } /* Name.Exception */
.highlight .nf { color: #268bd2 } /* Name.Function */
.highlight .nl { color: #657b83 } /* Name.Label */
.highlight .nn { color: #b58900 } /* Name.Namespace */
.highlight .nx { color: #657b83 } /* Name.Other */
.highlight .py { color: #268bd2 } /* Name.Property */
.highlight .nt { color: #859900 } /* Name.Tag */
.highlight .nv { color: #cb4b16 } /* Name.Variable */
.highlight .ow { color: #859900 } /* Operator.Word */
.highlight .w { color: #fdf6e3 } /* Text.Whitespace */
.highlight .mb { color: #2aa198 } /* Literal.Number.Bin */
.highlight .mf { color: #2aa198 } /* Literal.Number.Float */
.highlight .mh { color: #2aa198 } /* Literal.Number.Hex */
.highlight .mi { color: #2aa198 } /* Literal.Number.Integer */
.highlight .mo { color: #2aa198 } /* Literal.Number.Oct */
.highlight .sb { color: #2aa198 } /* Literal.String.Backtick */
.highlight .sc { color: #2aa198 } /* Literal.String.Char */
.highlight .sd { color: #2aa198 } /* Literal.String.Doc */
.highlight .s2 { color: #2aa198 } /* Literal.String.Double */
.highlight .se { color: #cb4b16 } /* Literal.String.Escape */
.highlight .sh { color: #2aa198 } /* Literal.String.Heredoc */
.highlight .si { color: #cb4b16 } /* Literal.String.Interpol */
.highlight .sx { color: #2aa198 } /* Literal.String.Other */
.highlight .sr { color: #2aa198 } /* Literal.String.Regex */
.highlight .s1 { color: #2aa198 } /* Literal.String.Single */
.highlight .ss { color: #2aa198 } /* Literal.String.Symbol */
.highlight .bp { color: #268bd2; font-weight: bold } /* Name.Builtin.Pseudo */
.highlight .vc { color: #268bd2 } /* Name.Variable.Class */
.highlight .vg { color: #268bd2 } /* Name.Variable.Global */
.highlight .vi { color: #268bd2 } /* Name.Variable.Instance */
.highlight .il { color: #2aa198 } /* Literal.Number.Integer.Long */


/*.highlight .hll { background-color: #ffffcc }*/
/*.highlight .c { color: #999988; font-style: italic } [> Comment <]*/
/*.highlight .err { color: #a61717; background-color: #e3d2d2 } [> Error <]*/
/*.highlight .k { color: #000000; font-weight: bold } [> Keyword <]*/
/*.highlight .o { color: #000000; font-weight: bold } [> Operator <]*/
/*.highlight .cm { color: #999988; font-style: italic } [> Comment.Multiline <]*/
/*.highlight .cp { color: #999999; font-weight: bold; font-style: italic } [> Comment.Preproc <]*/
/*.highlight .c1 { color: #999988; font-style: italic } [> Comment.Single <]*/
/*.highlight .cs { color: #999999; font-weight: bold; font-style: italic } [> Comment.Special <]*/
/*.highlight .gd { color: #000000; background-color: #ffdddd } [> Generic.Deleted <]*/
/*.highlight .ge { color: #000000; font-style: italic } [> Generic.Emph <]*/
/*.highlight .gr { color: #aa0000 } [> Generic.Error <]*/
/*.highlight .gh { color: #999999 } [> Generic.Heading <]*/
/*.highlight .gi { color: #000000; background-color: #ddffdd } [> Generic.Inserted <]*/
/*.highlight .go { color: #888888 } [> Generic.Output <]*/
/*.highlight .gp { color: #555555 } [> Generic.Prompt <]*/
/*.highlight .gs { font-weight: bold } [> Generic.Strong <]*/
/*.highlight .gu { color: #aaaaaa } [> Generic.Subheading <]*/
/*.highlight .gt { color: #aa0000 } [> Generic.Traceback <]*/
/*.highlight .kc { color: #000000; font-weight: bold } [> Keyword.Constant <]*/
/*.highlight .kd { color: #000000; font-weight: bold } [> Keyword.Declaration <]*/
/*.highlight .kn { color: #000000; font-weight: bold } [> Keyword.Namespace <]*/
/*.highlight .kp { color: #000000; font-weight: bold } [> Keyword.Pseudo <]*/
/*.highlight .kr { color: #000000; font-weight: bold } [> Keyword.Reserved <]*/
/*.highlight .kt { color: #445588; font-weight: bold } [> Keyword.Type <]*/
/*.highlight .m { color: #009999 } [> Literal.Number <]*/
/*.highlight .s { color: #d01040 } [> Literal.String <]*/
/*.highlight .na { color: #008080 } [> Name.Attribute <]*/
/*.highlight .nb { color: #0086B3 } [> Name.Builtin <]*/
/*.highlight .nc { color: #445588; font-weight: bold } [> Name.Class <]*/
/*.highlight .no { color: #008080 } [> Name.Constant <]*/
/*.highlight .nd { color: #3c5d5d; font-weight: bold } [> Name.Decorator <]*/
/*.highlight .ni { color: #800080 } [> Name.Entity <]*/
/*.highlight .ne { color: #990000; font-weight: bold } [> Name.Exception <]*/
/*.highlight .nf { color: #990000; font-weight: bold } [> Name.Function <]*/
/*.highlight .nl { color: #990000; font-weight: bold } [> Name.Label <]*/
/*.highlight .nn { color: #555555 } [> Name.Namespace <]*/
/*.highlight .nt { color: #000080 } [> Name.Tag <]*/
/*.highlight .nv { color: #008080 } [> Name.Variable <]*/
/*.highlight .ow { color: #000000; font-weight: bold } [> Operator.Word <]*/
/*.highlight .w { color: #bbbbbb } [> Text.Whitespace <]*/
/*.highlight .mf { color: #009999 } [> Literal.Number.Float <]*/
/*.highlight .mh { color: #009999 } [> Literal.Number.Hex <]*/
/*.highlight .mi { color: #009999 } [> Literal.Number.Integer <]*/
/*.highlight .mo { color: #009999 } [> Literal.Number.Oct <]*/
/*.highlight .sb { color: #d01040 } [> Literal.String.Backtick <]*/
/*.highlight .sc { color: #d01040 } [> Literal.String.Char <]*/
/*.highlight .sd { color: #d01040 } [> Literal.String.Doc <]*/
/*.highlight .s2 { color: #d01040 } [> Literal.String.Double <]*/
/*.highlight .se { color: #d01040 } [> Literal.String.Escape <]*/
/*.highlight .sh { color: #d01040 } [> Literal.String.Heredoc <]*/
/*.highlight .si { color: #d01040 } [> Literal.String.Interpol <]*/
/*.highlight .sx { color: #d01040 } [> Literal.String.Other <]*/
/*.highlight .sr { color: #009926 } [> Literal.String.Regex <]*/
/*.highlight .s1 { color: #d01040 } [> Literal.String.Single <]*/
/*.highlight .ss { color: #990073 } [> Literal.String.Symbol <]*/
.highlight .bp { color: #999999 } /* Name.Builtin.Pseudo */
.highlight .vc { color: #008080 } /* Name.Variable.Class */
.highlight .vg { color: #008080 } /* Name.Variable.Global */
.highlight .vi { color: #008080 } /* Name.Variable.Instance */
.highlight .il { color: #009999 } /* Literal.Number.Integer.Long */
@media (prefers-color-scheme: dark) {
    // Solarized Dark

    //For use with Jekyll and Pygments

    //http://ethanschoonover.com/solarized

    //SOLARIZED HEX      ROLE
    //--------- -------- ------------------------------------------
    //base03    #002b36  background
    //base01    #586e75  comments / secondary content
    //base1     #93a1a1  body text / default code / primary content
    //orange    #cb4b16  constants
    //red       #dc322f  regex, special keywords
    //blue      #268bd2  reserved keywords
    //cyan      #2aa198  strings, numbers
    //green     #859900  operators, other keywords
    //*/

    .highlight {
    //background-color: #002b36;
    color: #93a1a1
    }
    // .highlight { background-color: #282628; color: #93a1a1 }
    .highlight .lineno { color: #586e75 } /* Line Numbers */
    .highlight .c { color: #586e75 } /* Comment */
    .highlight .err { color: #93a1a1 } /* Error */
    .highlight .g { color: #93a1a1 } /* Generic */
    .highlight .k { color: #859900 } /* Keyword */
    .highlight .l { color: #93a1a1 } /* Literal */
    .highlight .n { color: #93a1a1 } /* Name */
    .highlight .o { color: #859900 } /* Operator */
    .highlight .x { color: #cb4b16 } /* Other */
    .highlight .p { color: #93a1a1 } /* Punctuation */
    .highlight .cm { color: #586e75 } /* Comment.Multiline */
    .highlight .cp { color: #859900 } /* Comment.Preproc */
    .highlight .c1 { color: #586e75 } /* Comment.Single */
    .highlight .cs { color: #859900 } /* Comment.Special */
    .highlight .gd { color: #2aa198 } /* Generic.Deleted */
    .highlight .ge { color: #93a1a1; font-style: italic } /* Generic.Emph */
    .highlight .gr { color: #dc322f } /* Generic.Error */
    .highlight .gh { color: #cb4b16 } /* Generic.Heading */
    .highlight .gi { color: #859900 } /* Generic.Inserted */
    .highlight .go { color: #93a1a1 } /* Generic.Output */
    .highlight .gp { color: #93a1a1 } /* Generic.Prompt */
    .highlight .gs { color: #93a1a1; font-weight: bold } /* Generic.Strong */
    .highlight .gu { color: #cb4b16 } /* Generic.Subheading */
    .highlight .gt { color: #93a1a1 } /* Generic.Traceback */
    .highlight .kc { color: #cb4b16 } /* Keyword.Constant */
    .highlight .kd { color: #268bd2 } /* Keyword.Declaration */
    .highlight .kn { color: #859900 } /* Keyword.Namespace */
    .highlight .kp { color: #859900 } /* Keyword.Pseudo */
    .highlight .kr { color: #268bd2 } /* Keyword.Reserved */
    .highlight .kt { color: #dc322f } /* Keyword.Type */
    .highlight .ld { color: #93a1a1 } /* Literal.Date */
    .highlight .m { color: #2aa198 } /* Literal.Number */
    .highlight .s { color: #2aa198 } /* Literal.String */
    .highlight .na { color: #93a1a1 } /* Name.Attribute */
    .highlight .nb { color: #B58900 } /* Name.Builtin */
    .highlight .nc { color: #268bd2 } /* Name.Class */
    .highlight .no { color: #cb4b16 } /* Name.Constant */
    .highlight .nd { color: #268bd2 } /* Name.Decorator */
    .highlight .ni { color: #cb4b16 } /* Name.Entity */
    .highlight .ne { color: #cb4b16 } /* Name.Exception */
    .highlight .nf { color: #268bd2 } /* Name.Function */
    .highlight .nl { color: #93a1a1 } /* Name.Label */
    .highlight .nn { color: #93a1a1 } /* Name.Namespace */
    .highlight .nx { color: #93a1a1 } /* Name.Other */
    .highlight .py { color: #93a1a1 } /* Name.Property */
    .highlight .nt { color: #268bd2 } /* Name.Tag */
    .highlight .nv { color: #268bd2 } /* Name.Variable */
    .highlight .ow { color: #859900 } /* Operator.Word */
    .highlight .w { color: #93a1a1 } /* Text.Whitespace */
    .highlight .mf { color: #2aa198 } /* Literal.Number.Float */
    .highlight .mh { color: #2aa198 } /* Literal.Number.Hex */
    .highlight .mi { color: #2aa198 } /* Literal.Number.Integer */
    .highlight .mo { color: #2aa198 } /* Literal.Number.Oct */
    .highlight .sb { color: #586e75 } /* Literal.String.Backtick */
    .highlight .sc { color: #2aa198 } /* Literal.String.Char */
    .highlight .sd { color: #93a1a1 } /* Literal.String.Doc */
    .highlight .s2 { color: #2aa198 } /* Literal.String.Double */
    .highlight .se { color: #cb4b16 } /* Literal.String.Escape */
    .highlight .sh { color: #93a1a1 } /* Literal.String.Heredoc */
    .highlight .si { color: #2aa198 } /* Literal.String.Interpol */
    .highlight .sx { color: #2aa198 } /* Literal.String.Other */
    .highlight .sr { color: #dc322f } /* Literal.String.Regex */
    .highlight .s1 { color: #2aa198 } /* Literal.String.Single */
    .highlight .ss { color: #2aa198 } /* Literal.String.Symbol */
    .highlight .bp { color: #268bd2 } /* Name.Builtin.Pseudo */
    .highlight .vc { color: #268bd2 } /* Name.Variable.Class */
    .highlight .vg { color: #268bd2 } /* Name.Variable.Global */
    .highlight .vi { color: #268bd2 } /* Name.Variable.Instance */
    .highlight .il { color: #2aa198 } /* Literal.Number.Integer.Long */
}
