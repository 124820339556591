
:root {
    color-scheme: light dark;

    --background-color: #fcfcfc;
    --text-color: #333;
    --link-color: rgb(51, 0, 204);
//rgb(0, 0, 230);//#2a7ae2;
    --hover-link-color: #1E3AFF;
    --code-border-color: rgba(42, 122, 226, 0.4);
    --code-background: #f0f0f0;//#f6f8fa;//rgba(27,31,35,.05);
    --pre-background:  #f0f0f0;// #f6f8fa;//rgba(27,31,35,.05);
    //background-color: rgba(27,31,35,.05);
    --grey-color: rgb(50, 50, 50);//$grey-color;
    --inline-code-color: #24292E;
    --pre-border-color:#888 !important;
    --quote-color: #6a737d;
    --quote-border-left: 4px solid #DFE2E5;
    --menu-color: #f6f6f6;
    --footer-color: #f4f4f4;
    --footnote-shadow-color: rgba(0, 0, 0, 0.3);
}

@media (prefers-color-scheme: dark) {
    :root {
        // [x] Get syntax highlighting working
        // [no fix] Implement toggle switch
        // [x] Figure out bookstrap issue (sizing/font/etc)
        // [x] footnote note size

        // --background-color: #121212; // safari reader moder
        --background-color: #151515; // webkit site
        --footer-color: #1F201F;
        // --background-color: #323233;
        --text-color: #DDD;
        --link-color: #99ddff;//85b4f4; // webkit blog
        // --link-color: #7FC6FF;
        --hover-link-color: #1ab2ff;//5A91FF;//#4661FF;//0000F3;//1E3AFF;
        --menu-color: #111; //#1F201F;
        --code-border-color: #313231;//rgba(42, 122, 226, 0.4);
        // --code-background: #313231;//#292729 !important;
        // --pre-background: #313231;//#282628 !important;
        // --code-background: #363636;
        // --code-background: #363636;
        --code-background: #252525;
        // --pre-background: #002b36; // solarized dark css
        // --pre-background: #363636; // Solarized Dark background w/ no saturation in HSV color space
        --pre-background: #252525;
        // --pre-background: #360000;
        //--code-background: #F3F3F3;
        --grey-color: $grey-color;
        --inline-code-color: $inline-code-color;
        --pre-border-color:#888 !important;
        --quote-border-left: 4px solid #353B42;
        --footnote-shadow-color: rgba(255, 255, 255, 0.3);
    }
}


div.pagination {
  text-align: center;
}
.previous{
  margin: 0em 2em 0em 2em;
}
.next{
  margin: 0em 2em 0em 2em;
}

/**
 * Reset some basic elements
 */
body, h1, h2, h3, h4, h5, h6,
p, blockquote, pre, hr,
dl, dd, ol, ul, figure {
    text-wrap: pretty;
    margin: 0;
    padding: 0;
    overflow-wrap: anywhere;
    word-wrap: normal; //break-word;
    padding-bottom: 5px;
    // word-break: break-all;
}

hr {
  border: 0;
  height: 2px;
  background: var(--quote-color);
  padding: 0;
  margin: 100;
}

input[type=range]{
    -webkit-appearance: none;
    width: 60% !important;
    margin-left: 20% !important;
    margin-right: 10% !important;
    background: #ddd;
    height: 7px;
    margin-top: 1em;
    margin-bottom: 1em;
}

input[type=range]::-webkit-slider-runnable-track {
    //width: 300px;
    //height: 7px;
    //background: #ddd;
    //border: none;
    //border-radius: 3px;
    //margin-bottom: 15px;
}

input[type=range]::-webkit-slider-thumb {
    -webkit-appearance: none;
    border: none;
    height: 18px;
    width: 18px;
    border-radius: 50%;
    /*background: goldenrod;*/
    background: #FF7F80;
    //margin-top: -4px;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: #ccc;
}

/**
 * Basic styling
 */
@font-face {
  font-family: 'Century';
  src: URL('/assets/fonts/century.ttf') format('truetype');
}

@font-face {
  font-family: "Source Serif";
  src: url("/assets/fonts/SourceSerif/SourceSerif4-Regular.otf.woff2") format('woff2');
}

@font-face {
  font-family: "Source Serif";
  font-weight: bold;
  src: url("/assets/fonts/SourceSerif/SourceSerif4-Bold.otf.woff2") format('woff2');
}

@font-face {
  font-family: "Source Serif";
  src: url("/assets/fonts/SourceSerif/SourceSerif4-Italic.otf.woff2") format('woff2');
  font-style: italic;
}

@font-face {
  font-family: "Source Serif";
  src: url("/assets/fonts/SourceSerif/SourceSerif4-BoldIt.otf.woff2") format('woff2');
  font-weight: bold;
  font-style: italic;
}

@font-face {
  font-family: charter;
  font-style: normal;
  font-weight: normal;
  font-stretch: normal;
  src: url('/assets/fonts/Charter/charter_regular.woff2') format('woff2');
}

@font-face {
  font-family: charter;
  font-style: italic;
  font-weight: normal;
  font-stretch: normal;
  src: url('/assets/fonts/Charter/charter_italic.woff2') format('woff2');
}

@font-face {
  font-family: charter;
  font-style: normal;
  font-weight: bold;
  font-stretch: normal;
  src: url('/assets/fonts/Charter/charter_bold.woff2') format('woff2');
}

@font-face {
  font-family: charter;
  font-style: italic;
  font-weight: bold;
  font-stretch: normal;
  src: url('/assets/fonts/Charter/charter_bold_italic.woff2') format('woff2');
}

body {
    font-weight: $base-font-weight;
    font-size: $base-font-size;
    line-height: $base-line-height;
    font-family: $base-font-family !important;
    color: var(--text-color);
    background-color: var(--background-color);

    @include media-query($on-palm) {
      font-weight: $base-font-weight;
      font-size: $small-font-size;
      line-height: $base-line-height;
      font-family: $base-font-family !important;
    }
    text-rendering: optimizeLegibility;
    font-feature-settings: "kern" $font-kern;
    font-kerning: normal;
    -webkit-text-size-adjust: 100%;
    -webkit-font-feature-settings: "kern" $font-kern;
    -moz-font-feature-settings: "kern" $font-kern;
    -o-font-feature-settings: "kern" $font-kern;
}
p {
    margin-top: 0.5em;
    max-inline-size: 70ch;
}
h1, h2, h3 {
    font-weight: $header-font-weight;
    font-size: $header-font-size;
    line-height: $base-line-height;
    font-family: $header-font-family !important;
    padding-top: 0.8em;
}
h1 {
    //font-weight: 500 !important;
    padding-top: 0em;
}

h2 {
    font-size: 24px !important;//1.250em !important;
    font-weight: $header-font-weight !important;
    //border-bottom-color: rgb(234, 236, 239);
    //border-bottom-style: solid;
    //border-bottom-width: 1px;
    //margin-bottom: 16px;
    margin-left: 0px;
    margin-right: 0px;
    margin-top: 0px;
    margin-bottom: 16px;
    //padding-bottom: 9.6px;
}

h3 {
    font-size: 20px !important;
    font-weight: $header-font-weight !important;
    line-height: 1.3;
    margin-bottom: 16px;
    margin-top: 24px;
    margin-right: 0px;
    margin-top: 0px;
}

// h1::before{
    // content: "# ";
// }
// h2::before{
    // content: "## ";
// }
// h3::before{
    // content: "### ";
// }
// h1::before, h2::before, h3::before{
    // color: #ccc;
// }

h4, h5, h6 {
    font-size: 18px !important;
    font-weight: $header-font-weight !important;
    line-height: 1.3;
    margin-bottom: 16px;
    margin-top: 24px;
}


/**
 * Set `margin-bottom` to maintain vertical rhythm
 */
h1, h2, h3, h4, h5, h6,
p, blockquote,
ul, ol, dl, figure,
%vertical-rhythm {
    margin-bottom: $spacing-unit / 2;
}

/**
 * Images
 */
img {
    max-width: 95%;
    vertical-align: middle;
    padding: 15px;
}
img.center {
    display: block;
    margin: 0 auto;
}
.center {
    margin-left: auto;
    margin-right: auto;
    display: block
}



/**
 * Figures
 */
figure > img {
    display: block;
}

figcaption {
    font-size: $small-font-size;
}



/**
 * Lists
 */
ul, ol {
    margin-left: $spacing-unit;
}

li {
    > ul,
    > ol {
         margin-bottom: 0;
    }
}



/**
 * Headings
 */
// h1, h2, h3, h4, h5, h6 {
    // font-weight: $base-font-weight;
// }



/**
 * Links
 */

a {
    color: var(--link-color);
    text-decoration: none;

    // Fade in the color when we hover
    -webkit-transition: background $fade-time linear;
    -moz-transition: background $fade-time linear;
    -ms-transition: background $fade-time linear;
    -o-transition: background $fade-time linear;
    transition: background $fade-time linear;
    padding-left: 0px;
    padding-right: 0px;

    code {
        color: var(--link-color);
        // border-color: var(--code-border-color);
    }

    &:hover {
        text-decoration:underline;
        color: var(--hover-link-color);
        // border-radius: 5px;
    }
}



/**
 * Blockquotes
 */
blockquote {
    color: var(--quote-color);
    border-left: var(--quote-border-left);
    padding-left: $spacing-unit / 2;
    font-size: $base-font-size !important;
    //font-style: italic;

    > :last-child {
        margin-bottom: 0;
    }
}

.footnotes {
    color: #888 !important;
}

.footnote {
    font-size: $extra-small-font-size;
    opacity: 0.9;
}


/**
 * Code formatting
 */

code { // inline
    // padding: 0px 0px;
    // border: 0;
    // padding-right: 0;
    // padding-left: 0;

    // font-size: 13px !important;
    // border-width: 1px !important;
    // border-radius: 1px;
    //padding: .2em .4em;
    margin: 0;
    font-size: 16px;//85%;
    background-color: var(--code-background);
    border-radius: 3px;
    // white-space: pre;

    // word-wrap: normal !important;
    // white-space: pre !important;
}

// pre {  // display
//     overflow-x: auto !important;
//     border-color: var(--pre-border-color);
//
//     // font-size: 15px !important;
//     border-width: 2px !important;
//     border-radius: 5px;
//     background-color: var(--pre-background);
//     /*overflow-wrap: normal;*/
//     white-space: pre;
//
//     // word-wrap: normal !important;
//     // white-space: pre !important;
//     margin: 5px;
//     padding: 5px;
//     // padding: 16px;
// }
pre {
    //padding: 5px;
    //overflow: auto;
    //// font-size: 85%;
    //// line-height: 1.45;
    //margin-bottom: 0;
    //word-break: normal;
    //white-space: none;
    //text-indent: -0.25em !important;

  border-radius: 3px;
  background-color: var(--pre-background) !important;

  // From sphinx readthedocs theme pre tag
  white-space: pre;
  margin: 0;
  padding: 12px;
  display: block;
  overflow: auto;
  word-wrap: normal; // for safari from [1]
  //line-height: 1.4rem;
  // [1]:https://stackoverflow.com/questions/44754987/getting-osx-safari-to-honor-css-white-space-pre
}

.highlighter-rouge {
    color: var(--inline-code-color);
}

/**
 * Wrapper
 */
.wrapper {
    max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit} * 2));
    max-width:         calc(#{$content-width} - (#{$spacing-unit} * 2));
    margin-right: auto;
    margin-left: auto;
    padding-right: $spacing-unit;
    padding-left: $spacing-unit;
    @extend %clearfix;

    // @include media-query($on-laptop) {
        // max-width: -webkit-calc(#{$content-width} - (#{$spacing-unit}));
        // max-width:         calc(#{$content-width} - (#{$spacing-unit}));
        // padding-right: $spacing-unit / 2;
        // padding-left: $spacing-unit / 2;
    // }
}


/**
 * Clearfix
 */
%clearfix {

    &:after {
        content: "";
        display: table;
        clear: both;
    }
}



/**
 * Icons
 */
.icon {

    > svg {
        display: inline-block;
        width: 16px;
        height: 16px;
        vertical-align: middle;

        path {
            fill: var(--grey-color);
        }
    }
}

.tooltip {
    display: none;
    position: absolute;
    border: 1px solid #333;
    background-color: #eee;
    border-radius: 5px;
    padding: 2px 7px 2px 7px;
    box-shadow: 2px 2px 10px #999;
}
