@charset "utf-8";

$code-background: #F3F3F3;
//$inline-code-color: #BA223D;
//$inline-code-color: #9D3E00;
$inline-code-color: #24292E;//000;
//$menu-color: darken(#0000FF, 10%);
$link-color: darken(#2a7ae2, 50%);
$link-visited-color: darken(#2a7ae2, 20%);
$hover-color: darken($link-color, 20%);
$hover-color-background: rgba(65, 152, 255, 0.13);
$footer-color: #f0f0f0;

// title: serif (bold/heavy)
// body: serif
// captions/etc: sans
// code: mono

// https://practicaltypography.com
// Fonts:
// - Body (serif)
// - Navigation (sans serif)
// - (heading?) (body, but italic?)

$base-font-family: "Charter", "Georgia", serif;
$font-kern: 1;
$base-font-size: 20px; //19px;
$base-font-weight: 400;
$small-font-size:  18px;
$extra-small-font-size: 14px;
$base-line-height: 1.40;

// links, etc
$nav-font-family: "-apple-system", "BlinkMacSystemFont", "Source Sans Pro", Helvetica,Arial,sans-serif;
$nav-font-size: 20px;

// h1, h2, h3, ...
$header-font-family: "-apple-system-headline", "BlinkMacSystemFont", "Source Sans Pro", Helvetica,Arial,sans-serif;
//$header-font-family: "Source Serif", "Century", "Palatino", "Georgia", serif;
$header-font-size: 28px;
$header-font-weight: 500;

$spacing-unit: 15px;

$text-color:       #444; //rgb(36, 41, 46);
$background-color: #fffff8;
$footer-color: #aaa;
$brand-color:      #2a7ae2;

$grey-color:       #828282;
$grey-color-light: lighten($grey-color, 20%);
$grey-color-dark:  darken($grey-color, 20%);

// how long we want to wait before hover fade in color
$fade-time: 0.25s;

// Variables for headers (h1, h2...)
//$header-font-family: "Century", "Georgia", serif;

// variables for title (Scott Sievert)
//$title-font-family: "Century", "Georgia", serif;

// Width of the content area
$content-width: 660px;
$on-palm:       640px;
$on-laptop:     904px;

// Use media queries like this:
// @include media-query($on-palm) {
//     .wrapper {
//         padding-right: $spacing-unit / 2;
//         padding-left: $spacing-unit / 2;
//     }
// }
@mixin media-query($device) {
    @media screen and (max-width: $device) {
        @content;
    }
}

// Import partials from `sass_dir` (defaults to `_sass`)
@import
        "base",
        "layout",
        "syntax-highlighting"
;
